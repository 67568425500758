
import _ from "underscore";
import { Component, Vue } from "vue-property-decorator";
import * as CountryService from "@/api/helpers/Country";
import { findCampaigns, Campaign as SMSCampaign } from "@/api/tharbad";
import { countryCode2FullNameFilter } from "@/filters";
import { CatalogItem } from "@/models/Catalog";
import AddBulkShipmentDrawer from "@/components/sms/AddBulkShipmentDrawer.vue"; //cambiar
import Notify from "@/utils/notifications";

interface SortParams {
  columns: Record<string, unknown>;
  order: string | null;
  prop: string;
}
interface Params {
  limit: number;
  offset: number;
  query: {
    name?: string;
    "country-code"?: string;
    "start-shipment-at"?: string;
    "end-shipment-at"?: string;
    complete?: string;
    sort?: string;
  };
}

@Component({
  components: { AddBulkShipmentDrawer },
  filters: { countryCode2FullNameFilter },
})
export default class CampaignTemplatesView extends Vue {
  fullscreenLoading = true;
  displayChecks = false;
  campaignArray = [];
  countReceivers = 0;
  currentPage = 1;
  pageNumberOfElementsOptions = [10, 25, 50, 100, 250, 500, 1000];
  defaultParams = {
    query: {
      name: undefined,
      "start-shipment-at": null,
      "end-shipment-at": null,
      "country-code": undefined,
      complete: null,
      sort: "-created-at",
    },
    limit: 10,
    offset: 0,
  };
  params: Params = _.clone(this.defaultParams);
  shipmentAt = [];
  currentItem: SMSCampaign | null = null;
  items = {} as Payload<SMSCampaign>;
  countries: CatalogItem<string>[] = [];
  showDrawer = false;

  async sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.query.sort = undefined;
    } else {
      this.params.query.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getItems();
  }

  async getItems(page = this.currentPage) {
    this.currentPage = page;
    this.fullscreenLoading = true;
    this.params.offset = (page - 1) * this.params.limit;
    this.items = await findCampaigns({
      ...this.params.query,
      limit: this.params.limit,
      offset: this.params.offset,
    });
    this.fullscreenLoading = false;
  }

  clearDate(data) {
    if (data == null) {
      this.params.query["start-shipment-at"] = undefined;
      this.params.query["end-shipment-at"] = undefined;
    } else {
      this.params.query["start-shipment-at"] = data[0];
      this.params.query["end-shipment-at"] = data[1];
    }
  }

  created() {
    const index = "6.1";
    const title = "Campañas de SMS";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "SMS",
        to: {
          name: "sms-campaign",
        },
      },
      {
        text: "Campañas",
      },
    ]);
    Promise.all([
      CountryService.findCountryCodes().then(data => {
        this.countries = data.map(x => {
          return { id: x.countryCode, description: x.description };
        });
      }),
      this.getItems(),
    ]);
  }

  closeDrawer() {
    this.showDrawer = false;
  }

  getReceivers() {
    const camp = _.filter(this.items.rows, i => {
      return _.contains(this.campaignArray, i.id);
    });
    this.countReceivers = _.reduce(
      camp,
      (memo, num) => {
        return memo + num.receivers;
      },
      0
    );

    this.showDrawer = !this.showDrawer;
  }

  notify(length) {
    Notify.successful(
      `Se ${length > 1 ? "crearon" : "creó"} ${length} ${
        length > 1 ? "campañas" : "campaña"
      }.`
    );
    this.getItems();
    this.displayChecks = false;
    this.campaignArray = [];
  }
}
