
import { Component, Prop, Vue } from "vue-property-decorator";
import ShipmentForm from "./ShipmentForm.vue";
import { findTemplates, postBulkShipment, Shipment } from "@/api/tharbad";
import Notify from "@/utils/notifications";
import _ from "underscore";

@Component({
  components: { ShipmentForm },
})
export default class AddTemplateDrawer extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop() campaigns!: Array<number>;
  @Prop() totalSMS!: number;

  fullscreenLoading = false;
  loading = false;
  itemData = {
    sender: "",
    content: "",
    "max-size": 0,
    "schedule-date": "",
  } as Shipment;

  templates = [{}];
  campaign = "";

  created() {
    this.getTemplates();
  }

  async getTemplates() {
    const templates = await findTemplates({});
    this.templates = _.map(templates.rows, t => {
      return { id: t.id, name: t.name };
    });
  }

  get isVisible() {
    return this.show;
  }

  private getForm() {
    return (this.$refs["form"] as HTMLFormElement).$refs["form"];
  }

  closeDrawer() {
    this.loading = false;
    const form = this.getForm();
    form.resetFields();
    form.clearValidate();
    this.$emit("closeDrawer", false);
  }

  async validateForm() {
    const form = this.getForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      form.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }

  protected toggleLoading(flag: boolean) {
    this.fullscreenLoading = flag;
    this.loading = flag;
  }

  processItem() {
    this.validateForm()
      .then(async isValid => {
        const params = this.itemData;
        if (this.itemData !== null && isValid) {
          try {
            this.toggleLoading(true);
            params["schedule-date"] = new Date(params["schedule-date"]);
            params.campaigns = this.$props.campaigns;
            const shipments = await postBulkShipment(params);
            this.$emit("updateView", shipments.data.data.length);
            this.closeDrawer();
          } catch (e) {
            if (e.data === undefined) {
              Notify.error((e as Error).message);
            } else {
              Notify.gebServerError(e as Error);
            }
          }
        }
      })
      .finally(() => {
        this.toggleLoading(false);
      });
  }
}
